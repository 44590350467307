import { parseDate, parseDateTime } from '@internationalized/date'

export const useSafeISODate = (date?: Date | string | null) => {
  let safeDate = undefined

  if (!date) {
    return safeDate
  }

  if (date instanceof Date) {
    return date.toISOString().split('T')[0]
  }

  try {
    parseDate(date)
    safeDate = date
  }
  catch (_e) {
    try {
      parseDateTime(date)
      safeDate = date.split('T')[0]
    }
    catch (_e) {
      console.error(`invalid date format "${date}"`)
    }
  }

  return safeDate
}
